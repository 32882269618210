<template>
  <div class="expande-horizontal wrap pa-3 pl-1 pt-0 pb-0 pr-0 fonte">
    <v-flex xs12>
      <v-text-field
        dense
        filled
        append-inner-icon="mdi-magnify"
        label="Faça uma busca..."
        v-model="filter.search"
        hide-details
        :color="$theme.primary"
        @input="updateList"
      ></v-text-field>
    </v-flex>
    <v-flex xs12>
      <v-switch label="apenas pendentes" v-model="filter.only_pendings">Apenas Pendentes</v-switch>
    </v-flex>
    <v-flex xs12>
      <v-divider></v-divider>
    </v-flex>
  </div>
</template>
<script>
export default {
  props: ["filter"],
  methods: {
    updateList() {
      this.$forceUpdate();
    }
  }
};
</script>
