<template>
  <div class="expande-horizontal column pt-0">
    <v-flex xs12>
      <div class="windows-style expande-horizontal fonte column">
        <transition name="slide-fade">
          <v-list-item class="pa-0 ma-0">
            <v-avatar
              @click="backToList"
              style="cursor: pointer;"
              size="27"
              :color="$theme.primary"
              class="mr-2"
              icon
            >
              <v-icon color="#f7f7f7">mdi-close</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="">
                {{
                  get_withdraw.new
                    ? "Novo withdraw"
                    : get_withdraw.nome
                }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-mini">
                Gerencie os detalhes abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="get_withdraw._id">
              <v-btn
                color="red"
                @click="excluir_withdraw_dialog(get_withdraw)"
                outlined
                small
                >Excluir</v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </transition>
      </div>
    </v-flex>
    <div class="windows-style-content expande-horizontal wrap">
      <v-flex xs12 class="px-3 fonte">
        <h3>Saldo do vendedor: {{ $helper.formataSaldo(balance) }}</h3>
      </v-flex>
      <v-divider></v-divider>
      <h2 class="fonte ma-3 mb-0 font-weight-light">
        Valor solicitado: {{ $helper.formataSaldo(get_withdraw.value) }}
      </h2>
      <v-flex xs12 class="orange--text pb-3 px-3">
        <span class="fonte">Este saque escontra-se: {{ get_withdraw.status }}</span>
      </v-flex>
      <v-flex xs12>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12>
        <v-list color="transparent" class="pa-0 ma-0 fonte">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ get_withdraw.bank_account.description }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Nome da conta
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ get_withdraw.bank_account.bank }}
              </v-list-item-title>
              <v-list-item-subtitle>
                banco
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
  
          <v-list-item v-if="get_withdraw.bank_account.agency">
            <v-list-item-content>
              <v-list-item-title>
                {{ get_withdraw.bank_account.agency }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Agência
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
  
          <v-list-item v-if="get_withdraw.bank_account.account_type">
            <v-list-item-content>
              <v-list-item-title>
                {{ get_withdraw.bank_account.account_type }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Tipo de Conta
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
  
          <v-list-item v-if="get_withdraw.bank_account.pix">
            <v-list-item-content>
              <v-list-item-title>
                {{ get_withdraw.bank_account.pix }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Pix
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-flex>
    </div>

    <div class="windows-style-bottom expande-horizontal pa-3">
      <v-btn :loading="loading" v-if="get_withdraw.status == 'waiting'" dark @click="approve_withdraw" tile :color="$theme.primary">
        <span class="fonte"> Atualizar paga pago </span>
      </v-btn>
      <v-btn :loading="loading" class="ml-2" v-if="get_withdraw.status == 'waiting'" dark @click="reprove_withdraw" tile color="red">
        <span class="fonte"> Reprovar saque </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import ModalViewTransaction from "@/apps/Transacoes/components/modalView.vue";
import extenso from "extenso";

export default {
  // components: { ModalViewTransaction },
  data() {
    return {
      tab: 0,
      transactions: [],
      emit_vale_salario_form: {
        value: ""
      },
      emit_vale_salario_dialog: false,
      balance: 0,
    };
  },
  computed: {
    ...mapGetters([
      "get_withdraw",
      "get_insumos",
      "get_permissions",
      "get_permissions_filtros",
      "get_modal_view_withdraw",
      "getLoggedUser"
    ]),
  },
  methods: {
    ...mapActions([
      "criar_withdraw",
      "list_permissions",
      "listar_insumos",
      "atualizar_withdraw",
      "listarProfessores",
      "fecha_modal_view_withdraw",
      "abre_modal_view_transacao",
      "enableLoading",
      "disableLoading",
    ]),
    backToList() {
      this.$store.commit("set_withdraw", {});
    },
    approve_withdraw() {
      this.loading = true;
      this.enableLoading();
      this.$run('balance-report/approve-withdraw', this.get_withdraw)
      .then((res) => {
        this.loading = false;
        this.disableLoading();
        this.$store.commit('set_withdraw', res.data);
        this.$store.dispatch('createGlobalMessage', {
          type: 'success',
          message: 'Status atualizado para aprovado!',
          timeout: 5000
        })
      })
    },
    reprove_withdraw() {
      this.loading = true;
      this.enableLoading();
      this.$run('balance-report/reprove-withdraw', this.get_withdraw)
      .then((res) => {
        this.loading = false;
        this.disableLoading();
        this.$store.commit('set_withdraw', res.data);
        this.$store.dispatch('createGlobalMessage', {
          type: 'success',
          message: 'Status atualizado para reprovado!',
          timeout: 5000
        })
      })
    },
    openValeSalarioEmitter(type) {
      this.emit_vale_salario_dialog = true;
      this.emit_vale_salario_form = {
        withdraw: this.getLoggedUser,
        cliente: this.get_withdraw,
        type: type,
        value: ""
      };
    },
    closeValeSalarioEmitter() {
      this.emit_vale_salario_dialog = false;
    },
    formatNumberToExtense() {
      const value = this.emit_vale_salario_form.value || "0";
      const value_replaced = parseInt(value.replace(",", "."));
      this.emit_vale_salario_form.value_extense = extenso(value_replaced, {
        mode: "currency",
        currency: { type: "BRL" }
      });
      this.$forceUpdate();
    },
    emitVale() {
      if (this.$refs.formValeSalario.validate()) {
        this.$store.dispatch("enableLoading");
        this.$run("transacoes/criar-vale", this.emit_vale_salario_form).then(
          res => {
            this.emit_vale_salario_form._id = res._id ? res._id : res.data._id;
            this.$store.dispatch("disableLoading");
            this.closeValeSalarioEmitter();
            this.getValeSalario();
            this.$refs.emitterValeSalario.generatePdf();
          }
        );
      }
    },
    emitSalario() {
      if (this.$refs.formValeSalario.validate()) {
        this.$store.dispatch("enableLoading");
        this.$run("transacoes/criar-salario", this.emit_vale_salario_form).then(
          res => {
            this.emit_vale_salario_form._id = res._id ? res._id : res.data._id;
            this.$store.dispatch("disableLoading");
            this.closeValeSalarioEmitter();
            this.getValeSalario();
            this.$refs.emitterValeSalario.generatePdf();
          }
        );
      }
    },
    getValeSalario() {
      this.$run("transacoes/get-vale-salario", {
        _id: this.get_withdraw._id
      }).then(res => {
        this.transactions = res.data;
      });
    },
    emitSalarioVale() {
      if (this.emit_vale_salario_form.type === "vale") {
        this.emitVale();
      }
      if (this.emit_vale_salario_form.type === "salario") {
        this.emitSalario();
      }
    },
    listSellerBalance() {
      this.$run('seller/get-seller-balance', {
        _id: this.get_withdraw.seller._id
      }).then((res) => {
        this.balance = res.data.balance
      })
    }
  },
  created() {
    this.listSellerBalance();
  }
};
</script>

<style>
.item-transac {
  background: #f0f0f2;
  border-radius: 6px;
  margin-bottom: 6px;
}
</style>